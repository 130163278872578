.site-navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  display: flex;
  position: relative;
}

.brand-logo, .default-logo, .responsive-logo {
  max-width: 128px;
  margin-right: 35px;
  transition: all .4s;
  position: relative;
}

@media (width >= 576px) {
  .site-navbar {
    padding-top: 0;
  }
}

@media (width >= 992px) {
  .site-navbar {
    flex-flow: row;
    justify-content: flex-start;
  }
}

.site-navbar .menu-block-wrapper {
  flex-grow: 1;
  align-items: center;
}

@media (width >= 992px) {
  .site-navbar .menu-block-wrapper {
    flex-basis: auto;
    display: flex !important;
  }
}

.site-header {
  background-color: #0000;
}

.site-header--absolute {
  z-index: 999;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.site-header--sticky:not(.mobile-sticky-enable) {
  z-index: 999;
  width: 100%;
  top: 0;
  right: 0;
  position: absolute !important;
}

@media (width >= 992px) {
  .site-header--sticky:not(.mobile-sticky-enable) {
    transition: all .4s;
  }

  .site-header--sticky:not(.mobile-sticky-enable).scrolling {
    transition: all .4s;
    transform: translateY(-100%);
  }

  .site-header--sticky:not(.mobile-sticky-enable).reveal-header {
    z-index: 1000;
    transform: translateY(0%);
    box-shadow: 0 12px 34px -11px #413e651a;
  }
}

.site-header--sticky.mobile-sticky-enable {
  z-index: 999;
  width: 100%;
  transition: all .4s;
  top: 0;
  right: 0;
  position: fixed !important;
}

.site-header--sticky.mobile-sticky-enable.scrolling {
  transition: all .4s;
  transform: translateY(-100%);
}

.site-header--sticky.mobile-sticky-enable.reveal-header {
  z-index: 9999;
  transform: translateY(0%);
  box-shadow: 0 12px 34px -11px #413e651a;
}

.site-header--menu-center .container {
  position: relative;
}

.site-header--menu-center .menu-block-wrapper {
  position: static;
}

@media (width >= 992px) {
  .site-header--menu-center .menu-block {
    margin-left: auto;
    margin-right: auto;
  }

  .site-header--menu-left .container-fluid .sub-menu.megamenu {
    left: 0%;
    transform: translateX(0%)translateY(10px);
  }

  .site-header--menu-left .container-fluid .nav-item.has-megamenu:hover > .sub-menu {
    margin-left: 20px;
    left: 0%;
    transform: translateX(0%)translateY(-10px);
  }
}

.site-header--menu-left .menu-block .site-menu-main {
  justify-content: flex-start;
  padding-left: 15px;
}

@media (width >= 1200px) {
  .site-header--menu-left .menu-block {
    width: 100%;
  }
}

.site-header--menu-right .menu-block-wrapper {
  position: static;
}

.site-header--menu-right .menu-block {
  margin-left: auto;
}

@media (width >= 992px) {
  .site-header > .container-fluid .sub-menu.megamenu {
    left: 50%;
    transform: translateX(-50%)translateY(10px);
  }

  .site-header > .container-fluid .nav-item.has-megamenu:hover > .sub-menu {
    left: 50%;
    transform: translateX(-50%)translateY(-10px);
  }
}

@media (width >= 768px) {
  .container-fluid .site-navbar {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (width >= 992px) {
  .container-fluid .site-navbar {
    padding-left: 48px;
    padding-right: 48px;
  }
}

@media (width >= 480px) {
  .header-btns {
    margin-right: 15px;
  }
}

@media (width >= 992px) {
  .header-btns {
    margin-right: 0;
  }
}

.top-contact-menu-wraper .header-btn {
  order: 3;
}

.top-contact-menu-wraper .mobile-menu-trigger {
  order: 4;
}

@media (width >= 992px) {
  .top-contact-menu-wraper .nav-link-item {
    color: #fff !important;
  }

  .top-contact-menu-wraper .site-menu-main .nav-link-item {
    padding-top: 19px !important;
    padding-bottom: 19px !important;
  }

  .top-contact-menu-wraper .navbar {
    flex-wrap: wrap;
    padding: 30px 0 0;
  }

  .top-contact-menu-wraper .brand-logo {
    order: 1;
    min-width: 50%;
    padding-bottom: 30px;
  }

  .top-contact-menu-wraper .header-btn {
    order: 4;
  }

  .top-contact-menu-wraper .menu-block-wrapper {
    background: #1d263a;
    border-radius: 0 0 0 5px;
    order: 3;
    min-width: calc(100% - 256px);
  }

  .top-contact-menu-wraper .top-contact-block {
    order: 2;
    justify-content: flex-end;
    min-width: 50%;
    padding-bottom: 8px;
    display: flex !important;
  }

  .top-contact-menu-wraper .menu-block {
    margin-left: initial;
  }
}

.top-contact-block ul {
  align-items: center;
  display: flex;
}

.top-contact-block ul li {
  align-items: center;
  display: inline-flex;
}

.top-contact-block span {
  background: #1d243a1f;
  width: 1px;
  height: 30px;
  margin: 0 35px;
}

.top-contact-block img {
  margin-right: 12px;
}

.top-contact-block a {
  color: #1d263a;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 30px;
  display: flex;
}

@media (width >= 992px) {
  .row-lg {
    display: flex;
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.site-menu-main {
  flex-direction: row;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

@media (width < 992px) {
  .site-menu-main {
    flex-direction: column;
    width: 100%;
  }
}

@media (width >= 992px) {
  .site-menu-main {
    flex-direction: row;
    column-gap: 30px;
  }
}

@media (width > 1200px) {
  .site-menu-main {
    column-gap: 50px;
  }
}

.site-menu-main li {
  list-style: none;
}

.site-menu-main ul {
  margin-left: 0;
  list-style: none;
}

.site-menu-main a {
  text-decoration: none;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

@media (width >= 992px) {
  .navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.site-menu-main .nav-item {
  display: inherit;
}

.site-menu-main .nav-link-item {
  color: var(--heading-color);
  letter-spacing: normal;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  position: relative;
}

.site-menu-main .nav-link-item--white {
  color: #fff;
}

.site-menu-main > .nav-item-has-children > .drop-trigger {
  line-height: 70px;
}

@media (width < 991px) {
  .site-menu-main .nav-link-item {
    width: 100%;
    display: block;
  }
}

.site-menu-main.is-text-white .nav-link-item {
  color: #fff;
}

.site-menu-main.is-text-white .nav-link-item .dropdown-icon {
  filter: invert();
}

@media (width < 992px) {
  .site-menu-main.is-text-white .nav-link-item .dropdown-icon {
    filter: invert(0%);
  }
}

.aximo-header1 .site-menu-main .nav-link-item {
  color: var(--white-smoke);
}

.aximo-header2 .site-menu-main .nav-link-item {
  color: var(--heading-color2);
}

.dark-mode-texts .nav-link-item {
  color: #fff;
}

.site-menu-main .sub-menu {
  z-index: 500;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  border-radius: 6px;
  min-width: 250px;
  margin: 0;
  padding: 12px 0;
  transition: all .4s;
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(20px);
  box-shadow: -2px 2px 70px -25px #0000004d;
}

.site-menu-main .sub-menu:before {
  content: "";
  z-index: 8;
  border-bottom: 12px solid #fff;
  border-left: 12px solid #0000;
  border-right: 12px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: -12px;
  left: 22px;
}

.sub-menu.megamenu:before, .site-menu-main .sub-menu.shape-none:before {
  content: none;
}

@media (width >= 992px) {
  .site-menu-main .sub-menu .sub-menu {
    left: 100%;
    top: 0 !important;
  }
}

.site-menu-main .sub-menu--item {
  font-size: 16px;
  font-weight: 600;
}

@media (width <= 992px) {
  .site-menu-main .sub-menu--item {
    border-bottom: 1px solid #0000001a;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (width >= 992px) {
  .site-menu-main .sub-menu--item {
    color: #000;
  }
}

.site-menu-main .sub-menu--item > a {
  color: #000;
  padding: 8px 20px;
}

@media (width >= 992px) {
  .site-menu-main .sub-menu--item > a {
    transition: all .4s;
  }
}

.site-menu-main .sub-menu--item:hover > a {
  color: #3147ff;
}

@media (width >= 992px) {
  .site-menu-main .sub-menu.megamenu {
    background-color: #fff;
  }
}

.site-menu-main .sub-menu.megamenu .dropdown-image-block {
  max-height: 336px;
}

@media (width >= 320px) and (width <= 992px) {
  .site-menu-main .sub-menu.megamenu {
    border: 0;
  }
}

@media (width >= 992px) {
  .site-menu-main .sub-menu.megamenu {
    will-change: transform;
    visibility: hidden;
    z-index: -99;
    opacity: 0;
    pointer-events: none;
    border: 1px solid #e7e9ed;
    border-radius: 8px;
    min-width: 925px;
    padding: 30px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%)translateY(10px);
    box-shadow: 0 42px 54px #00000017;
  }
}

@media (width >= 1200px) {
  .site-menu-main .sub-menu.megamenu {
    min-width: 1100px;
  }
}

@media (width >= 1366px) {
  .site-menu-main .sub-menu.megamenu {
    min-width: 900px;
  }
}

.site-menu-main .sub-menu.megamenu .single-dropdown-block .mega-drop-menu-item {
  padding-top: 8px;
  padding-bottom: 8px;
  display: block;
}

.site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
  color: #000;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  font-size: 15px;
  font-weight: 600;
  transition: all .4s;
}

@media (width >= 320px) and (width <= 992px) {
  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
    color: var(--color-headings);
    border-top: 1px solid var(--border-color-3);
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 20px;
  }
}

@media (width >= 992px) {
  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
    padding-left: 0;
    padding-right: 10px;
  }

  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item:hover {
    color: #ffc947 !important;
  }
}

@media (width >= 320px) and (width <= 992px) {
  .site-menu-main .sub-menu.megamenu [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }

  .site-menu-main .sub-menu.megamenu [class*="row-"] {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (width >= 992px) {
  li.nav-item-has-children:not(.has-megamenu) {
    position: relative;
  }

  li.nav-item-has-children:hover > .sub-menu {
    visibility: visible;
    opacity: 1;
    z-index: 99;
    pointer-events: visible;
    border-radius: 6px;
    transform: translateY(0);
  }
}

.site-menu-main > li .sub-menu > ul > li > a {
  color: #555;
  text-transform: capitalize;
  padding: 10px 0;
  font-size: 15px;
  text-decoration: none;
  transition: color .3s;
  display: inline-block;
}

.site-menu-main > li .sub-menu.mega-menu {
  left: 50%;
  transform: translateX(-50%);
}

.mobile-menu-head, .mobile-menu-trigger {
  display: none;
}

@media (width <= 991px) {
  .site-header .mobile-menu-trigger {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-left: 40px;
    display: flex;
  }

  .site-header .mobile-menu-trigger span {
    background-color: #13111a;
    width: 24px;
    height: 2px;
    display: block;
    position: relative;
  }

  .mobile-menu-trigger.is-white span {
    background-color: #fff !important;
  }

  .reveal-header .mobile-menu-trigger span {
    background-color: #13111a !important;
  }

  .reveal-header .mobile-menu-trigger.is-white span {
    background-color: #fff !important;
  }

  .site-header .mobile-menu-trigger span:before, .site-header .mobile-menu-trigger span:after {
    content: "";
    background-color: #13111a;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
  }

  .site-header .mobile-menu-trigger.is-white span:before, .site-header .mobile-menu-trigger.is-white span:after {
    background-color: #fff;
  }

  .reveal-header .mobile-menu-trigger span:before, .reveal-header .mobile-menu-trigger span:after {
    background-color: #13111a !important;
  }

  .dark-mode-texts .mobile-menu-trigger span, .dark-mode-texts .mobile-menu-trigger span:before, .dark-mode-texts .mobile-menu-trigger span:after {
    background-color: #13111a;
  }

  .site-header .mobile-menu-trigger span:before {
    top: -8px;
  }

  .site-header .mobile-menu-trigger span:after {
    top: 8px;
  }

  .site-header .item-right {
    align-items: center;
  }

  .site-header .menu-block {
    z-index: 1099;
    background-color: #fff;
    width: 320px;
    height: 100%;
    transition: all .5s;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    transform: translate(-100%);
  }

  .site-header .menu-block.active {
    transform: translate(0%);
  }

  .site-menu-main > li {
    margin: 0;
    line-height: 1;
    display: block;
  }

  .site-menu-main > li > a {
    border-bottom: 1px solid #0000001a;
    height: 50px;
    padding: 0 50px 0 15px;
    line-height: 50px;
    display: block;
  }

  .site-menu-main > li > a i {
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(-90deg);
  }

  .site-header .menu-block .mobile-menu-head {
    z-index: 501;
    background-color: #fff;
    border-bottom: 1px solid #0000001a;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .site-header .menu-block .mobile-menu-head .go-back {
    cursor: pointer;
    text-align: center;
    color: #000;
    border-right: 1px solid #0000001a;
    width: 50px;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
    display: none;
  }

  .site-header .menu-block .mobile-menu-head.active .go-back {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .site-header .menu-block .mobile-menu-head.active .go-back img {
    transform: rotate(180deg);
  }

  .site-header .menu-block .mobile-menu-head .current-menu-title {
    color: #000;
    visibility: hidden;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 500;
  }

  .site-header .menu-block .mobile-menu-head.active .current-menu-title {
    visibility: visible;
  }

  .site-header .menu-block .mobile-menu-head .mobile-menu-close {
    cursor: pointer;
    text-align: center;
    color: #000;
    border-left: 1px solid #0000001a;
    width: 50px;
    height: 50px;
    font-size: 25px;
    line-height: 50px;
  }

  .site-header .menu-block .site-menu-main {
    height: 100%;
    overflow: hidden auto;
  }

  .site-menu-main > li .sub-menu.mega-menu, .site-menu-main > li .sub-menu {
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    width: 100%;
    min-width: auto;
    max-width: none;
    height: 100%;
    margin: 0;
    padding: 50px 0 15px;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden auto;
    transform: translateX(0%);
  }

  .site-menu-main > li .sub-menu.active, .site-menu-main > li .sub-menu > ul > li > a, .site-menu-main > li .sub-menu.mega-menu > .list-item > ul > li > a {
    display: block;
  }

  .site-menu-main > li .sub-menu.mega-menu > .list-item > ul {
    margin-bottom: 15px;
  }

  .menu-overlay {
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    background-color: #00000080;
    width: 100%;
    height: 100%;
    transition: all .5s;
    position: fixed;
    top: 0;
    left: 0;
  }

  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}

@media (width >= 992px) {
  .has-megamenu ul {
    margin-left: auto;
  }

  .site-menu-main a {
    align-items: center;
    display: flex;
  }

  .site-menu-main i {
    margin-left: 15px;
    font-size: 15px;
  }
}

@media (width >= 1200px) {
  .site-header--menu-center .menu-block-wrapper {
    left: 50%;
    transform: translateX(-50%);
    position: absolute !important;
  }
}

.nav-item-has-children a {
  text-transform: capitalize;
  justify-content: space-between;
  display: flex;
}

.reveal-header {
  background: #0d152e !important;
}

.reveal-header .nav-link-item, .reveal-header .btn, .reveal-header .btn:hover {
  color: #fff !important;
}

.reveal-header .dark-version-logo {
  display: block !important;
}

.reveal-header .light-version-logo {
  display: none !important;
}

@media (width >= 992px) {
  .reveal-header li.nav-item-has-children:hover > .sub-menu {
    top: 100%;
  }

  .reveal-header li.nav-item-has-children:hover > .megamenu {
    top: 112%;
  }
}

.menu-block.active .nav-link-item {
  color: #000 !important;
}

@media (width >= 480px) {
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}

.nav-link-item.drop-trigger {
  align-items: center;
  column-gap: 5px;
  display: flex;
}

ul.site-menu-main .nav-item .dropdown-icon {
  transition: all .4s ease-in-out;
  transform: rotate(90deg);
}

ul.site-menu-main .nav-item:hover .dropdown-icon {
  transform: rotate(0);
}

li.sub-menu--item.nav-item-has-children:hover .dropdown-icon {
  transform: rotate(0) !important;
}

li.sub-menu--item.nav-item-has-children .dropdown-icon {
  transition: all .4s ease-in-out;
  transform: rotate(0) !important;
}

.single-dropdown-block h3 {
  opacity: .5;
  color: #000;
  letter-spacing: 1.1px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 25px;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: none;
}

@media (width >= 992px) {
  .single-dropdown-block h3 {
    display: block;
  }
}

.landing-2-menu .navbar {
  border-bottom: 1px solid #1d243a1f;
}
/*# sourceMappingURL=index.7852f3fc.css.map */
